/* eslint-disable */
import { focusableSelectors } from './helpers'

const TAB_KEY = 'Tab'
const ESCAPE_KEY = 'Escape'
const SPACE_KEY = ' '
const ENTER_KEY = 'Enter'

interface DKDialogOptions {
  element: HTMLElement
  focusTrapQuery: string
}

class DKDialog {
  $el: Element
  shown: Boolean
  _focusTrapQuery: string
  _previouslyFocused: null | DKDialog['$el']
  _listeners: {} | { string: [Function] }

  constructor({ element, focusTrapQuery }: DKDialogOptions) {
    this.$el = element
    this.shown = false
    this._focusTrapQuery = focusTrapQuery
    this._previouslyFocused = null
    this._listeners = {}
  }

  create() {
    this._fire('create', null)

    return this
  }

  show = (event: null | Event) => {
    if (this.shown) {
      return this
    }

    this._previouslyFocused = document.activeElement
    this.shown = true

    setFocusToFirstItem(this.$el)

    document.body.addEventListener('focus', this._maintainFocus, true)
    document.addEventListener('keydown', this._bindKeypress)

    this._fire('show', event)

    return this
  }

  hide = (event: null | Event) => {
    if (!this.shown) {
      return this
    }

    this.shown = false

    if (this._previouslyFocused && this._previouslyFocused.focus) {
      this._previouslyFocused.focus()
    }

    document.body.removeEventListener('focus', this._maintainFocus, true)
    document.removeEventListener('keydown', this._bindKeypress)

    this._fire('hide', event)

    return this
  }

  on(type: string, handler: Function) {
    if (typeof this._listeners[type] === 'undefined') {
      this._listeners[type] = []
    }

    this._listeners[type].push(handler)

    return this
  }

  off(type: string, handler: Function) {
    var index = (this._listeners[type] || []).indexOf(handler)

    if (index > -1) {
      this._listeners[type].splice(index, 1)
    }

    return this
  }

  _fire(type: string, event: null | Event) {
    var listeners = this._listeners[type] || []

    listeners.forEach(
      function (listener) {
        listener(this.$el, event)
      }.bind(this),
    )
  }

  bindButtonKeypress = (event: KeyboardEvent) => {
    if (event.key === SPACE_KEY || event.key === ENTER_KEY) {
      event.preventDefault()
      if (!this.shown) {
        this.show(event)
      } else if (this.shown) {
        this.hide(event)
      }
    }
  }

  _bindKeypress = (event: KeyboardEvent) => {
    if (this.shown && event.key === ESCAPE_KEY && this.$el.getAttribute('role') !== 'alertdialog') {
      event.preventDefault()
      this.hide(event)
    }

    if (this.shown && event.key === TAB_KEY) {
      trapTabKey(this.$el, event)
    }
  }

  _maintainFocus = (event: FocusEvent) => {
    const target = event.target as HTMLElement

    if (this.shown && !target.closest(this._focusTrapQuery) && !target.closest('[dk-dialog-ignore-focus-trap]')) {
      setFocusToFirstItem(this.$el)
    }
  }
}

function toArray(collection: NodeListOf<Element>) {
  return Array.from(collection)
}

function $$(selector: string, context: Element) {
  return toArray((context || document).querySelectorAll(selector))
}

function setFocusToFirstItem(node: Element) {
  var focusableChildren = getFocusableChildren(node)
  var focused = node.querySelector('[autofocus]') || focusableChildren[0]

  if (focused instanceof HTMLElement) {
    setTimeout(() => focused.focus(), 300)
  }
}

function getFocusableChildren(node: Element) {
  return $$(focusableSelectors.join(','), node).filter(function (child: HTMLElement) {
    return !!(child.offsetWidth || child.offsetHeight || child.getClientRects().length)
  })
}

function trapTabKey(node: Element, event: KeyboardEvent) {
  const focusableChildren = getFocusableChildren(node)
  let focusedItemIndex = document.activeElement ? focusableChildren.indexOf(document.activeElement) : null

  if (event.shiftKey && focusedItemIndex === 0) {
    focusableChildren[focusableChildren.length - 1].focus()
    event.preventDefault()
  } else if (!event.shiftKey && focusedItemIndex === focusableChildren.length - 1) {
    focusableChildren[0].focus()
    event.preventDefault()
  }
}

export default DKDialog
