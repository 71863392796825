import Accordion from './modules/Accordion'
import Anchors from './modules/Anchors'
import Cards from './modules/Cards'
import ConvertTags from './modules/ConvertTags'
import Dialog from './modules/Dialog'
import Dropdowns from './modules/Dropdowns'
import Nav from './modules/Nav'
import Tabs from './modules/Tabs'
import { startDKApp } from '../core/app'
import { bootstrap } from '../core/bootstrap'
import ContactForm from './components/ContactForm'

import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

startDKApp(() => {
  Accordion()
  Anchors()
  Cards()
  ConvertTags()
  Dialog()
  Dropdowns()
  Nav()
  Tabs()
  bootstrap('form-contact', [ContactForm], {})
  bootstrap('form-about', [ContactForm], {})
  bootstrap('form-partners', [ContactForm], {})
  bootstrap('form-path-medical', [ContactForm], {})
  bootstrap('form-path-transportation', [ContactForm], {})
  bootstrap('form-path-customer-service', [ContactForm], {})
  bootstrap('form-path-energy', [ContactForm], {})
})

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: Number(process.env.SENTRY_SAMPLE_RATE),
})
