import { StoreRecord } from './store'
import { isObservable } from 'rxjs'
type InputElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement

/**
 * Applies the value of an input element to the key of a given StoreRecord
 * The input must have the attribute `dk-input` set to the key that you want to update.
 * For example: `<input type="text" dk-input="firstName">` would correspond with a record containing the key `firstName`
 *
 * @param input
 * @param record
 */
export function parseInput (input: InputElement, record: StoreRecord<any>): void {
  const subject = record.getValue()
  const key = input?.getAttribute('dk-input') as string
  subject[key] = input?.value

  record.next(subject)
}

/**
 * Takes the values of inputs, selects, and textarea of a form and converts them to an object.
 * The keys of the object match the value set in the dk-input attribute.
 *
 * Example:
 *
 * Given a form
 * ```html
 * <form dk-target="test-form">
 *    <input type="text" dk-input="first-name" value="Timmy">`
 * </form>
 * ```
 * calling `getInputValues('test-form')` would result in an object matching `{ 'first-name': 'Timmy' }`
 *
 * @param target
 * @returns
 */
export function getInputValues (target: HTMLFormElement): GenericObject<any> {
  const inputs: NodeListOf<HTMLInputElement> = target.querySelectorAll('[dk-input]')

  return Array.from(inputs).reduce((acc: GenericObject<any>, input) => {
    return { ...acc, [input.getAttribute('dk-input')!]: input.value }
  }, {})
}

/**
 * TODO: Add function description
 *
 * @param root
 * @param record
 */
export function setInputValues (root: Element, record: GenericObject<any> | StoreRecord<any>): void {
  const inputs = root.querySelectorAll('[dk-input]')! as NodeListOf<HTMLInputElement>
  const values = isObservable(record) ? record.getValue() : record

  Array.from(inputs).forEach((input) => {
    const key = input.getAttribute('dk-input')!
    input.value = values[key]
  })
}
