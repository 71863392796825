import { on } from '../../core/on'
import { mount } from '../../core/mount'
import { getInputValues } from '../../core/forms'
import { ajax } from 'rxjs/ajax'
import { Subject } from 'rxjs'
import type { ComponentActions } from '../../core/bootstrap'

/* eslint no-var: "off" */
declare var grecaptcha
declare var $

type FormStep = 'recaptcha' | 'constant-contact' | 'complete' | 'error'

export default function (root: RootElement): ComponentActions {
  let verifiedHuman = false
  let form: HTMLFormElement
  const API_URL = process.env.API_URL
  const RECAPTCHA_PUBLIC_KEY = process.env.RECAPTCHA_PUBLIC_KEY!
  const step$ = new Subject<FormStep>()

  /**
   * https://developers.google.com/recaptcha/docs/v3
   *
   * reCAPTCHA v3 returns a score for each request without user friction. The score is based on interactions with your
   * site and enables you to take an appropriate action for your site. Register reCAPTCHA v3 keys
   * [here](https://g.co/recaptcha/v3).
   */
  function handleRecaptcha () {
    grecaptcha.ready(() => {
      grecaptcha.execute(RECAPTCHA_PUBLIC_KEY, { action: 'submit' })
        .then((token) => {
          ajax.post(process.env.API_URL + 'recaptcha', { token }).subscribe({
            complete () {
              step$.next('constant-contact')
            },
            error () {
              step$.next('error')
            },
          })
        })
    })
  }

  /**
   * Submits the form to the /form endpoint.
   *
   * This will almost always be successful unless the service is down. If you need to troubleshoot any issues you'll
   * need to log into [Integromat](https://www.integromat.com/).
   */
  function handleConstantContact () {
    const values = getInputValues(form)
    values.memo ||= ''

    ajax.post(API_URL + 'form', values).subscribe({
      complete () { step$.next('complete') },
      error () { step$.next('error') },
    })
  }

  /**
   * Once the reCaptcha and Constant Contact actions are complete, submit the from again. The next time it's ran it will
   * bypass all of our custom actions and submit the form using the Webflow action.
   */
  function handleComplete () {
    verifiedHuman = true
    $(form).trigger('submit')
  }

  /**
   * TODO: Do something if there's an error with the form submission Because Integromat will always return success. It's
   *       likely because the user is a robot
   */
  function handleError () {
    console.error('There was an error submitting the form')
  }

  /**
   * Default action when the form is submitted
   *
   * @param event
   * @param _
   * @param target
   */
  function handleSubmit (event, _, target) {
    event.preventDefault()
    form = target

    // Webflow attaches the submit event handler to the document. Since we're submitting a form, we can block the event
    // propagation and check the user for signs of circuitry. However, if we know the user is not a bot we can just
    // sendit like my hero, Larry Enticer.
    if (verifiedHuman) return
    event.stopPropagation()

    step$.next('recaptcha')
  }

  /**
   * A lame (but functional) attempt at a state machine.
   *
   * @param step
   */
  function stepStepper (step) {
    switch (step) {
      case 'recaptcha':
        handleRecaptcha()
        break
      case 'constant-contact':
        handleConstantContact()
        break
      case 'complete':
        handleComplete()
        break
      default:
        handleError()
    }
  }

  return {
    watch: [
      { target: step$, actions: [stepStepper] },
    ],
    start: mount(root,
      on('contact-submission', handleSubmit),
    ),
  }
}
